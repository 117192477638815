/*
 * @Author: 关
 * @Date: 2022-08-29 15:03:00
 * @LastEditTime: 2022-08-29 15:03:00
 * @LastEditors: 关
 * @Description: web应用相关配置
 * @FilePath: \tp-vue2\src\config\website.js
 */

export default {
  appName: "网络科技有限公司", // 应用名称
  // firstPageRouterPath: '/index', // 登陆后首次显示的页面路由地址
};
