/*
 * @Author: 关
 * @Date: 2022-08-29 15:03:00
 * @LastEditTime: 2022-08-29 15:03:00
 * @LastEditors: 关
 * @Description: 公共信息状态管理
 * @FilePath: \tp-vue2\src\store\modules\common.js
 */

import website from '@/config/website'
const common = {
  state: {
    isRefresh: true,
    website: website,
  },
  mutations: {
    SET_IS_REFRESH: (state, refresh) => {
      state.isRefresh = refresh;
    },
  }
}
export default common;
